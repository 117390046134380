import { chakra } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ComponentProps } from 'react';

export const FaIcon = chakra(FontAwesomeIcon, {
   baseStyle: {
      display: 'flex',
      alignItems: 'center',
   },
});

export type FaIconProps = ComponentProps<typeof FaIcon>;
