import type { SVGProps } from 'react';

function SvgRepairEULogo(props: SVGProps<SVGSVGElement>) {
   return (
      <svg viewBox="0 0 24 24.02" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
         <path
            fill="currentColor"
            d="m24,12C24,5.37,18.63,0,12,0,5.37,0,0,5.37,0,12c0,0,0,.01,0,.02,0,6.63,5.37,12,12,12v-.02c6.63,0,12-5.37,12-12Zm-15.02,2.31c.48,1.25,1.71,2.06,3.06,2,1.34.06,2.57-.74,3.06-2l1.16.39c-.46,1.12-1.35,2.01-2.47,2.47-2.33.96-5-.14-5.96-2.47l1.16-.39Zm-.55-3.39c0-.64.52-1.16,1.16-1.16s1.16.52,1.16,1.16-.52,1.16-1.16,1.16-1.16-.52-1.16-1.16Zm5.97,1.16c-.64,0-1.16-.52-1.16-1.16s.52-1.16,1.16-1.16c0,0,0,0,0,0,.64,0,1.16.52,1.16,1.16s-.52,1.16-1.16,1.16Zm-2.3,9.33c-5.06.05-9.2-4-9.26-9.06-.05-5.06,4-9.2,9.06-9.26l-1.35-1.35,1.24-1.2,3.61,3.61-3.66,3.66-1.18-1.18,1.62-1.62h-.18c-2.38,0-4.6,1.16-5.96,3.12-2.28,3.29-1.47,7.81,1.82,10.09,3.29,2.28,7.81,1.47,10.09-1.82,2.28-3.29,1.47-7.81-1.82-10.09l1.11-1.55c2.45,1.71,3.92,4.51,3.92,7.49,0,0,0,0,0,0h0s0,0,0,0c0,5.02-4.04,9.1-9.06,9.15Z"
         />
         <polygon points="21.16 12.26 21.16 12.27 21.16 12.27 21.16 12.26" />
      </svg>
   );
}

export default SvgRepairEULogo;
